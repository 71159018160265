import React from 'react'
import { Link } from "gatsby"
import * as style from "../static/styles/contact404pp.module.scss"
import Seo from "../components/seo"
import Footer from "../components/footer"

const NotFound = () => {
  return (
    <div className={style.contact404ppRoot}>
      <Seo title="404: Not Found"/>
      <div className={style.insideWrapper404}>
          <h1>Page Not Found</h1>
          <Link to="/">Return</Link>
      </div>
      <Footer/>
    </div>
  )
}

export default NotFound
